.GroupInput {
  display: flex;
  align-items: center;
  justify-content: center;
}
.g-input{
  color: white;
}

.GroupInput input {
  width: 4em;
  text-align: center;
  border: 2px solid gray;
  outline: none;
  padding: 0.5em;
  font-size: 1em;
  border-radius: 0.3em;
}

.GroupInput label {
  margin-right: 1em;
}

.GroupInput button {
  height: 3em;
  width: 6em;
  border: none;
  background-color: rgb(47, 47, 47);
  color: white;
  border-radius: 0.3em;
  cursor: pointer;
  margin-left: 2em;
  transition: all ease 0.5s;
}

.GroupInput button:hover {
  background-color: rgb(25, 25, 25);
  transform: scale(1.1, 1.1) translateY(-5px);
}
