.content-container {
    padding: 20px;
    margin: 30px 20px 20px 20px;

    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; 
    border-radius: 10px;
    background-color: rgb(19, 58, 133);
  }
  
  .wrapper-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  