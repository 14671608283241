.List {
  padding: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all ease 0.5s;
}

.ListItem {
  width: 20em;
  display: flex;
  align-items: center;
  justify-content: space-around; 
  background-color: #f9f9f9;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0.5em 0;
}


.ListItem p {
  margin: 0;
  padding: 0.2em;
  flex-grow: 1;
  border: none;
  background: none;
}

.rm-btn {
  height: 2em;
  width: 6em;
  letter-spacing: 1px;
  border: none;
  background-color:rgb(252, 169, 3);
  color: black;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: all ease 0.3s;
}

.rm-btn:hover {

  transform: scale(1.03, 1.03) translateY(-3px);
}

@media only screen and (max-width: 767px) {
  .ListItem {
    width: 15em;
  }
}
