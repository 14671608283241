.Input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5em;
  min-width: 20em;
}

.Input input {
  border: 2px solid gray;
  outline: none;
  padding: 0.5em;
  font-size: 1em;
  border-radius: 0.3em;
}

.Input button {
  height: 2em;
  width: 4em;
  border: none;
  background-color: rgb(47, 47, 47);
  color: white;
  border-radius: 0.3em;
  cursor: pointer;
  margin-left: 2em;
  transition: all ease 0.5s;
}

.Input button:hover {
  background-color: rgb(25, 25, 25);
  transform: scale(1.1, 1.1) translateY(-5px);
}
