* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.wrapper-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer-sec {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; 
  margin-top: 20em;
}

.footer-sec .footer-icons a {
  display: inline-block;
  text-decoration: none;
  color: #1f1f1f;
  margin-right: 10px;
}
.footer-sec .footer-icons a i {
  display: block;
  height: 60px;
  width: 60px;
  font-size: 24px;
  color: #1f1f1f;
  line-height: 64px;
  text-align: center;
  border-radius: 40px;
  background: transparent;
  -webkit-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.footer-sec .footer-icons a i.la-facebook-f:hover,
.footer-sec .footer-icons a i.la-facebook-f:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #3d558f inset;
  box-shadow: 0 0 15px 30px #3d558f inset;
}
.footer-sec .footer-icons a i.la-twitter:hover,
.footer-sec .footer-icons a i.la-twitter:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #33c9dd inset;
  box-shadow: 0 0 15px 30px #33c9dd inset;
}
.footer-sec .footer-icons a i.la-google:hover,
.footer-sec .footer-icons a i.la-google:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #d74937 inset;
  box-shadow: 0 0 15px 30px #d74937 inset;
}
.footer-sec .footer-icons a i.la-linkedin-in:hover,
.footer-sec .footer-icons a i.la-linkedin-in:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #0474bc inset;
  box-shadow: 0 0 15px 30px #0474bc inset;
}

.footer-sec .footer-icons a i.la-instagram:hover,
.footer-sec .footer-icons a i.la-instagram:focus {
  color: #ffffff;
  background-image: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  /*-webkit-box-shadow: 0 0 15px 30px #fd5949 inset;*/
  /*box-shadow: 0 0 15px 30px #fd5949 inset;*/
  animation: 1s instaRound;
}
.footer-sec .footer-icons a i.la-envelope:hover,
.footer-sec .footer-icons a i.la-envelope:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #d45049 inset;
  box-shadow: 0 0 15px 30px #d45049 inset;
}

@-webkit-keyframes instaRound {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes instaRound {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.intro-banner-vdo-play-btn .ripple:nth-child(2) {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.intro-banner-vdo-play-btn .ripple:nth-child(3) {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}
