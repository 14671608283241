.GroupList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 3em;
  width: 42em;
}



.SaveButtonContainer {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.Group {
  padding: 1em 3em;
  margin: 1em 3em;
  margin-bottom: 20px;
  border-radius: 0.5em;
  background-color: #1c8adb;
  color: white;
}

.Group ul {
  display: flex;
}

.Group ul li {
  margin: 0.5em 1em;
}

@media only screen and (max-width: 767px) {
  .GroupList {
    width: 30em;
  }
}
